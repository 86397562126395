$(function fadeNavbar() {
    $(document).scroll(function () {
    var $nav = $(".navbar");
    // $nav.toggleClass('scrolled-nav', $(this).scrollTop() > $nav.height()-20 );
    $nav.toggleClass('scrolled-hero', $(this).scrollTop() > $nav.height()+600 );
    });
});

var mybutton = document.getElementById("gotoTop");

window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    if (document.body.scrollTop > 640 || document.documentElement.scrollTop > 640) {
        mybutton.style.display = "block";
    } else {
        mybutton.style.display = "none";
    }
};

function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};
